import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const CommunityEmpowermentSign = () => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const animateFrameRef = useRef(null);

  useEffect(() => {
    if (!mountRef.current) return;
    // Set up the scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff); // White background

    const camera = new THREE.PerspectiveCamera(50, 2, 0.1, 1000);
    camera.position.set(0, 0, 20); // Move closer to focus only on the cube

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    rendererRef.current = renderer;
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Create spheres representing individuals in the community
    const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
    const sphereMaterial = new THREE.MeshStandardMaterial({
      color: 0x4299cf,
      emissive:0x4299cf
    });

    const individuals = [];
    const individualsCount = 8; // Number of individuals
    const radius = 5; // Radius for placing individuals in a circle

    for (let i = 0; i < individualsCount; i++) {
      const angle = (i / individualsCount) * Math.PI * 2;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;

      const individualMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
      individualMesh.position.set(x, y, 0);
      scene.add(individualMesh);
      individuals.push(individualMesh);
    }

    // Create a central glowing sphere symbolizing empowerment
    const centralGeometry = new THREE.SphereGeometry(2, 32, 32);
    const centralMaterial = new THREE.MeshStandardMaterial({
      color: 0xab0a0f,
      emissive: 0xab0a0f
    });

    const centralMesh = new THREE.Mesh(centralGeometry, centralMaterial);
    scene.add(centralMesh);

    // Add lighting to highlight the community symbol
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1.5, 100);
    pointLight.position.set(10, 15, 20);
    scene.add(pointLight);

    const centralLight = new THREE.PointLight(0xffd700, 1.5, 50);
    centralLight.position.set(0, 0, 0);
    scene.add(centralLight);

    // Animation loop to add rotation to the entire group
    const animate = () => {
      animateFrameRef.current = requestAnimationFrame(animate);
      individuals.forEach((individual) => {
        individual.rotation.y += 0.01; // Rotate each individual slowly
      });
      centralMesh.rotation.y += 0.01; // Rotate the central empowerment symbol
      scene.rotation.z += 0.005; // Rotate the entire group for a dynamic effect
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      if (!mountRef.current || !rendererRef.current) return;

      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (animateFrameRef.current) {
        cancelAnimationFrame(animateFrameRef.current);
      }
      if(mountRef.current && rendererRef.current) {
        try { 
          mountRef.current.removeChild(rendererRef.current.domElement);
        } catch (error) {
          console.error('Error removing renderer dom element:', error);
        }
      }

      // dispose of three.js resources
      renderer.dispose();
      scene.traverse(object => {
        if (object instanceof THREE.Mesh) {
          object.geometry.dispose();
          object.material.dispose();
        }
      });
      scene.clear();
    };
  }, []);

  return <div ref={mountRef} style={{ width: '300px', height: '150px' }} />;
};

export default CommunityEmpowermentSign;
