import React, { useState } from 'react';
import TermsAndConditions from './TermsAndConditions';
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore methods
import { db } from '../../config/firebase';

const WaitlistPopup = ({ isOpen, onClose }) => {
  const [isChecked, setIsChecked] = useState(false); // State to track checkbox
  const [isTermsOpen, setTermsOpen] = useState(false); // State to control terms modal
  const [successModalOpen, setSuccessModalOpen] = useState(false); // State to control success modal
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  if (!isOpen) return null;

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add formData to Firestore collection 'waitlist'
      await addDoc(collection(db, 'waitlist'), formData);
      console.log('Document successfully written!', formData);
      
      // Send waitlist confirmation email after successfully saving to Firestore
      await sendWaitlistConfirmation(formData.email, formData.name);
  
      setSuccessModalOpen(true); // Open success modal after successful submission
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const sendWaitlistConfirmation = async (to, name) => {
    try {
      const response = await fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to, name }),
      });
  
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  

  const openTerms = () => {
    setTermsOpen(true);
  };

  const closeTerms = () => {
    setTermsOpen(false);
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
    onClose(); // Close the original modal after closing the success modal
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md relative">
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
            onClick={onClose}
          >
            &times;
          </button>
          <h2 className="text-2xl text-center font-bold mb-4">Connect with us!</h2>
          <p className="text-gray-600 mb-6">
            Join our exclusive waitlist and get early access to the user dashboard, exciting rewards, and more. Don't miss out on the next wave of innovation!
          </p>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block font-semibold mb-1" htmlFor="name">Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 border rounded"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className="block font-semibold mb-1" htmlFor="email">E-mail ID*</label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 border rounded"
                placeholder="Enter email ID"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="terms"
                className="mr-2"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="terms" className="text-gray-600">
                I agree to the <button type="button" onClick={openTerms} className="text-customBlue underline">Terms and Conditions</button>
              </label>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={`bg-customBlue hover:bg-customRed transition-colors text-white py-2 px-6 rounded-lg mt-4 ${
                  !isChecked ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!isChecked}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Terms and Conditions Modal */}
      <TermsAndConditions isOpen={isTermsOpen} onClose={closeTerms} />

      {/* Success Modal */}
      {successModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md relative">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
              onClick={closeSuccessModal}
            >
              &times;
            </button>
            <h2 className="text-2xl text-center font-bold mb-4">Welcome to TrustBlu!</h2>
            <p className="text-gray-600 mb-6">
              You have successfully joined the waitlist. Thank you for connecting with us!
            </p>
            <div className="text-center">
              <button
                className="bg-customBlue hover:bg-customRed transition-colors text-white py-2 px-6 rounded-lg mt-4"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WaitlistPopup;