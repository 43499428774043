import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/Logo1.jpg';
import ModalImage from '../../assets/userRegister.jpg'; // Replace with your image path
import { Link } from "react-scroll";
import { useAuth } from "../../contexts/AuthContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [loading, setLoading] = useState(false);

  const { login, signup } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [donorCardNo, setDonorCardNo] = useState('');
  const [error, setError] = useState('');

  const [selectedRole, setSelectedRole] = useState('');
  const [adminSecretKey, setAdminSecretKey] = useState('');
  const [institutionCode, setInstitutionCode] = useState('');

  const navigate = useNavigate();
  const offsetValue = window.innerWidth > 1024 ? -100 : -70;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleSignInSignUp = () => {
    setIsSignIn(!isSignIn);
  };

  // memoize the scroll handler
  const handleScroll = useCallback(() => {
    if (window.innerWidth > 768 && !isOpen) {
      setIsScrolled(window.scrollY > 0);
    }
  }, [isOpen]);

  // Handle scroll event to add blur for laptop screen sizes and above
  useEffect(() => {
    // only add listener if component is mounted
    let isMounted = true;

    if (isMounted) {
      window.addEventListener("scroll", handleScroll);
    }

    // cleanup function
    return () => {
      isMounted = false;
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]); // add handleScroll as a dependency

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      if (isSignIn) {
        const response = await login(email, password);
        const role = response.role.toLowerCase();

        setShowModal(false);

        setTimeout(() => {
          navigate(`/${role}-dashboard`);
        }, 100);
      } else {
        // validation for sign up
        if (password !== confirmPassword) {
          setError('Passwords do not match');
          return;
        }

        if (selectedRole === '') {
          setError('Please select a role');
          return;
        }

        if (selectedRole === 'Admin' && adminSecretKey === '') {
          setError('Please enter the admin secret key');
          return;
        }

        if (selectedRole === 'Institution' && institutionCode === '') {
          setError('Please enter the institution code');
          return;
        }

        if (donorCardNo === '') {
          setError('Please enter the donor card number');
          return;
        }

        const response = await signup(email, password, selectedRole.toLowerCase(), institutionCode, adminSecretKey);
        if (!response || !response.role) {
          setError('Something went wrong');
          return;
        }

        const role = response.role.toLowerCase();
        setShowModal(false);

        await Promise.resolve(login(email, password));
        navigate(`/${role}-dashboard`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Hide spinner when request completes
    }
  };

  return (
    <>
      <header
        className={`fixed top-0 w-full py-3 z-50 ${
          isScrolled && !isOpen ? "backdrop-blur-md border-b border-gray-200 transition-all duration-300" : "bg-white border-b border-gray-200"
        } ${isOpen ? "bg-white border-b border-gray-200 !transition-none" : ""}`}
      >
        <div className="container mx-auto px-4 lg:px-0 flex items-center justify-between">
          {/* Logo Section */}
          <a className="flex items-center no-underline" href="#">
            <span className="text-customRed font-bold text-2xl">Trust</span>
            <span className="text-customBlue font-bold text-2xl">Blu</span>
            <img src={Logo} alt="Logo" className="h-10 -ml-1" />
          </a>

          {/* Hamburger for Mobile Screens */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>

          {/* Navbar Links - Hidden on mobile */}
          <ul className="hidden md:flex justify-center space-x-8 ml-10">
            {['Home', 'About', 'Why TrustBlu', 'Reward'].map((link, index) => (
              <li key={index} className="nav-item">
                <Link
                  to={link}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-customBlue hover:text-customRed transition-colors no-underline cursor-pointer"
                >
                  {link}
                </Link>
              </li>
            ))}
          </ul>

          {/* Right aligned buttons - Hidden on mobile */}
          <div className="hidden md:flex space-x-4 ml-auto">
            <Link
              to="Contact Us"
              spy={true}
              smooth={true}
              offset={offsetValue}
              duration={500}
              className="bg-customBlue text-white px-4 py-2 rounded"
            >
              Contact Us
            </Link>
            <button
              onClick={toggleModal}
              className="bg-customRed text-white px-4 py-2 rounded"
            >
              Start Donating
            </button>
          </div>
        </div>

        {/* Mobile Dropdown from the Right */}
        <div
          className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-40 transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="pt-16 px-6">
            {['Home', 'About', 'Why TrustBlu', 'Reward'].map((link, index) => (
              <Link
                key={index}
                to={link}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
                className="block text-customBlue hover:text-customRed transition-colors no-underline cursor-pointer mb-4"
              >
                {link}
              </Link>
            ))}
            <Link
              to="Contact Us"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={toggleMenu}
              className="block bg-customBlue text-white px-4 py-2 rounded hover:bg-customRed transition-colors mb-4"
            >
              Contact Us
            </Link>
            <button
              onClick={toggleModal}
              className="block bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors w-full"
            >
              Start Donating
            </button>
          </div>
        </div>

        {/* Gradient border at the bottom of header */}
        <div className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-red-500 animate-gradient"></div>
      </header>

      {/* Modal for Sign In / Sign Up Toggle */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[70rem] h-[40rem] shadow-lg flex relative">
            {/* Close Button */}
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Image Section */}
            <div className="w-full bg-cover bg-center rounded-l-lg" style={{ backgroundImage: `url(${ModalImage})` }}></div>
            
            {/* Form Section */}
            <div className="w-2/3 p-6">
              <h2 className="text-2xl font-bold mb-4 text-center">
                {isSignIn ? "Sign In" : "Sign Up"}
              </h2>

              {/* Error Message */}
              {error && (
                <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded relative">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{error}</span>
                <button
                  className="absolute top-0 right-0 px-4 py-3"
                  onClick={() => setError('')}
                >
                  <span className="text-red-500 hover:text-red-700">×</span>
                </button>
                </div>
              )}

              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-500">
                  {isSignIn ? "Switch to Sign Up" : "Switch to Sign In"}
                </span>
                <label className="relative inline-block w-10 h-6">
                  <input
                    type="checkbox"
                    checked={!isSignIn}
                    onChange={toggleSignInSignUp}
                    className="opacity-0 w-0 h-0"
                  />
                  <span className="absolute inset-0 bg-gray-300 rounded-full transition-colors duration-300 ease-in-out cursor-pointer checked:bg-blue-500"></span>
                  <span
                    className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                      !isSignIn ? "translate-x-4" : ""
                    }`}
                  ></span>
                </label>

              </div>
              
              {/* Sign In Form */}
              {isSignIn ? (
                <form onSubmit={handleSubmit}>
                  <input
                    type="email" 
                    placeholder="Email" 
                    className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue" 
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                  <input
                    type="password" 
                    placeholder="Password" 
                    className="mb-6 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                  <button 
                    type="submit" 
                    className="bg-customBlue text-white w-full py-2 rounded hover:bg-customRed transition-colors"
                  >
                    Sign In
                  </button>
                </form>
              ) : (
                /* Sign Up Form */
                <form onSubmit={handleSubmit}>
                  <input
                   type="text" 
                   placeholder="Name" 
                   className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                   onChange={(e) => setName(e.target.value)}
                   value={name}
                   required
                  />
                  <input
                    type="email" 
                    placeholder="Email" 
                    className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                  <input
                    type="password" 
                    placeholder="Password" 
                    className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                  <input
                    type="password" 
                    placeholder="Confirm Password" 
                    className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required
                  />
                  <input 
                    type="text" 
                    placeholder="Phone Number" 
                    className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    required
                  />
                  <select 
                    className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setSelectedRole(e.target.value)}
                    value={selectedRole}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="User">User</option>
                    <option value="Admin">Admin</option>
                    <option value="Institution">Institution</option>
                  </select>
                  {selectedRole === 'Admin' && (
                    <input 
                      type="password" 
                      placeholder="Admin Secret Key" 
                      className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                      onChange={(e) => setAdminSecretKey(e.target.value)}
                      value={adminSecretKey}
                      required
                    />
                  )}
                  {selectedRole === 'Institution' && (
                    <input
                      type="text" 
                      placeholder="Institution Code" 
                      className="mb-4 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                      onChange={(e) => setInstitutionCode(e.target.value)}
                      value={institutionCode}
                      required
                    />
                  )}
                  <input
                    type="text" 
                    placeholder="Donor Card No" 
                    className="mb-6 w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                    onChange={(e) => setDonorCardNo(e.target.value)}
                    value={donorCardNo}
                    required
                  />
                  <button
                    type="submit"
                    className="bg-customBlue text-white w-full py-2 rounded hover:bg-customRed transition-colors"
                  >
                    Sign Up
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Spinner Overlay */}
      {loading && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              </div>
            )}

      {/* Wrapper for blurring and darkening the background content */}
      <div
        className={`transition-all duration-300 ${
          isOpen || showModal ? "blur-sm bg-black bg-opacity-50 fixed inset-0 z-30" : ""
        }`}
      >
      </div>

      <style jsx>{`
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }
        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 5s linear infinite;
        }
      `}</style>
    </>
  );
};

export default Navbar;