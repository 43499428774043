import React, { useState } from 'react';

import TaskSection from './TaskSection';

import Navbar from './Navbar';
import NewNftMintingList from './NewNftMintingList';

const Dashboard = () => {
  const [account, setAccount] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar account={account} connectWallet={connectWallet} />

      {/* Main Dashboard Content */}
      <div className="flex-grow w-full px-4 lg:px-8 py-20 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Left Side */}
          <div className="lg:col-span-2 space-y-4">
            <NewNftMintingList />
            <TaskSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
