import React from 'react';
import Navbar from './Navbar';
import { useState } from 'react';

function InstitutionDashboard() {
  const [appointment, setAppointment] = useState([]);
  const [message, setMessage] = useState('');
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />

      <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-900">Appointment Details</h2>
            <p className="mt-2 text-gray-700">
              <strong>Location:</strong> {appointment.location}
            </p>
            <p className="text-gray-700">
              <strong>Donation Center:</strong> {appointment.donationCenter}
            </p>
            <p className="text-gray-700">
              <strong>Date:</strong> {appointment.date}
            </p>
            <p className="text-gray-700">
              <strong>Time:</strong> {appointment.time}
            </p>
            <button
              className="mt-4 px-4 py-2 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none"
            >
              Confirm Donation
            </button>
            {message && <p className="mt-4 text-sm text-green-600">{message}</p>}
          </div>
    </div>
  );
}

export default InstitutionDashboard;