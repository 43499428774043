import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { PencilIcon, EyeIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'; // Importing right arrow icon
import image1 from '../../assets/NFT2.webp';
import image2 from '../../assets/NFT3.webp';
import image3 from '../../assets/NFT4.webp';
import image4 from '../../assets/NFT5.webp';

const images = [image1, image2, image3, image4];

const ProfileSection = () => {
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    name: 'Alec Thompson',
    title: 'CEO / Co-Founder',
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };

  const handleProfileClick = () => {
    setIsEditModalOpen(true); // Open the modal for editing profile details
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="relative w-full flex flex-col items-center -mb-10">
      {/* Background Images with Framer Motion Scroll Effect */}
      <div className="relative w-full h-[350px] overflow-hidden mb-28 bg-white -mt-2">
        <motion.div
          className="absolute inset-0 h-full flex items-center"
          animate={{ x: ["0%", "-100%"] }}
          transition={{
            repeat: Infinity,
            duration: 40,
            ease: 'linear',
          }}
          style={{ zIndex: 1 }} // Lower z-index to keep it behind other elements
        >
          {[...images, ...images, ...images].map((imageUrl, index) => (
            <div key={index} className="flex-shrink-0 w-64 h-full p-2">
              <img
                src={imageUrl}
                alt={`Background ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </motion.div>
      </div>

      {/* Profile Card */}
      <div
        className="relative bg-gradient-to-l from-blue-500 to-red-500 p-6 shadow-lg flex items-center space-x-6 w-[100%] h-[150px] -mt-40 z-20 cursor-pointer"
      >
        {/* Profile Image Container */}
        {/* Profile Image Container */}
        <div className="relative w-24 h-24 rounded-full overflow-hidden shadow-md flex-shrink-0">
          <img
            src={profileImage}
            alt="Profile"
            className="w-full h-full object-cover rounded-full"
          />
          {/* Overlay with Icons */}
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
            {/* Edit Icon */}
            <div className="cursor-pointer mx-2" onClick={() => document.getElementById('file-upload').click()}>
              <PencilIcon className="h-8 w-8 text-white" />
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
            </div>
            {/* View Icon */}
            <div className="cursor-pointer mx-2" onClick={() => setIsModalOpen(true)}>
              <EyeIcon className="h-8 w-8 text-white" />
            </div>
          </div>
        </div>


        {/* Profile Details */}
        <div className="flex flex-col space-y-1">
          <div className="text-2xl font-semibold text-white">{profileDetails.name}</div>
          <div className="text-white text-sm">{profileDetails.title}</div>
        </div>

        {/* Social Media Icons */}
        <div className="flex space-x-3 ml-auto">
          <a
            href="#"
            className="bg-blue-600 p-2 rounded-md text-white shadow-md"
            aria-label="Facebook"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="#"
            className="bg-blue-400 p-2 rounded-md text-white shadow-md"
            aria-label="Twitter"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="#"
            className="bg-gray-800 p-2 rounded-md text-white shadow-md"
            aria-label="GitHub"
          >
            <i className="fab fa-github"></i>
          </a>
        </div>

        {/* Right Arrow Icon */}
        <div
          className="ml-auto cursor-pointer flex items-center justify-end w-[70%]"
          onClick={handleProfileClick} // Open edit modal on click
        >
          <ChevronDoubleRightIcon className="h-8 w-8 text-white" />
        </div>
      </div>

      {/* Modal to View Profile Image */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} center>
        <div className="w-full h-full p-4">
          <img src={profileImage} alt="Profile Large" className="w-full h-full object-cover rounded-lg" />
        </div>
      </Modal>

      {/* Modal to Edit Profile Details */}
      <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} center>
        <div className="p-6 flex flex-col space-y-4">
          <h2 className="text-xl font-bold">Edit Profile Details</h2>
          <label className="block">
            <span className="text-gray-700">Name</span>
            <input
              type="text"
              name="name"
              value={profileDetails.name}
              onChange={handleProfileChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Title</span>
            <input
              type="text"
              name="title"
              value={profileDetails.title}
              onChange={handleProfileChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
            />
          </label>
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md"
            onClick={() => setIsEditModalOpen(false)}
          >
            Save Changes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileSection;