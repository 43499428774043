import React, { useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datastore from "../../Datastore/dataStore.json";

Modal.setAppElement("#root");

const AppointmentsSection = () => {
  const appointmentDetails = {
    title: "Next Appointment",
    date: "October 10, 2024",
    time: "10:30 AM",
    location: "Downtown Health Center",
  };

  const questions = [
    "Are you 17 years old or more?",
    "Have you had a tattoo or piercing in the last three months?",
    "Have you travelled outside of Canada, the continental USA, Antarctica or Europe in the last three weeks?",
    "Have you been to the dentist for an extraction, surgery or root canal in the last three days?",
    "Are you pregnant, have been pregnant or had a baby in the last six months?",
    "Are you taking prescription medication(s)?",
    "Do you weigh more than 50 kg (110 lb.)?",
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState(false);
  const [responses, setResponses] = useState(questions.map(() => null));
  const [isEligible, setIsEligible] = useState(null); // Null indicates no result yet
  const [isEligibilityChecked, setIsEligibilityChecked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [centerName, setCenterName] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEligibilityModal = () => {
    setIsEligibilityModalOpen(true);
    setIsEligibilityChecked(false); // Reset eligibility check
    setResponses(questions.map(() => null)); // Reset responses
  };

  const closeEligibilityModal = () => {
    setIsEligibilityModalOpen(false);
  };

  const handleResponseChange = (index, answer) => {
    const newResponses = [...responses];
    newResponses[index] = answer;
    setResponses(newResponses);
  };

  const checkEligibility = () => {
    const rules = [
      (response) => response === "yes",
      (response) => response === "no",
      (response) => response === "no",
      (response) => response === "no",
      (response) => response === "no",
      (response) => response === "no",
      (response) => response === "yes",
    ];

    const eligible = responses.every((response, index) =>
      rules[index](response)
    );
    setIsEligible(eligible);
    setIsEligibilityChecked(true);
    closeEligibilityModal(); // Close modal after checking eligibility
  };


  const handleBookAppointment = async () => {
    // Retrieve the current user's email (replace with your actual method)
    const idToken = localStorage.getItem("idToken");
    const email = localStorage.getItem("userEmail");

    if (!email) {
      console.error("User email not found. Ensure the user is logged in.");
      return;
    }

    const appointmentData = {
      location: centerName, // Match the backend field name
      date: selectedDate.toISOString().split("T")[0], // Ensure ISO format
      time: selectedTime,
      email, // Use the logged-in user's email
      status: "scheduled", // Optional, but good to include if backend doesn't default it
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/appointments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Ensure token is present
        },
        body: JSON.stringify(appointmentData),
      });

      console.log(response);


      if (!response.ok) {
        throw new Error("Failed to book appointment");
      }

      const createdAppointment = await response.json();
      console.log("Appointment booked:", createdAppointment);
      closeModal();
    } catch (error) {
      console.error("Error booking appointment:", error);
    }
  };


  const timeSlots = [
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
  ];

  return (
    <motion.div
      className="p-4 bg-customGray text-white rounded-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <h2 className="text-lg font-bold mb-4 text-black">Appointments</h2>
      <div className="flex space-x-4">
        <button
          onClick={openEligibilityModal}
          className={`px-4 py-2 rounded-lg mb-6 w-[40%] ${isEligibilityChecked && isEligible ? "bg-green-500" : "bg-red-500"
            }`}
        >
          Check Eligibility
        </button>
        <button
          onClick={openModal}
          className={`px-4 py-2 rounded-lg mb-6 ${isEligible ? "bg-blue-500" : "bg-gray-500"
            }`}
          disabled={isEligible === null || !isEligible}
        >
          Book Appointment
        </button>
      </div>

      {/* Eligibility Result */}
      {isEligibilityChecked && (
        <p
          className={`text-lg font-bold mt-4 ${isEligible ? "text-green-600" : "text-red-600"
            }`}
        >
          {isEligible
            ? "You are eligible to donate!"
            : "You are not eligible to donate at this time."}
        </p>
      )}

      {/* Next Appointment Section */}
      <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md mt-6">
        <h3 className="text-lg font-bold mb-2">{appointmentDetails.title}</h3>
        <p className="mb-1">
          <span className="font-semibold">Date: </span>{appointmentDetails.date}
        </p>
        <p className="mb-1">
          <span className="font-semibold">Time: </span>{appointmentDetails.time}
        </p>
        <p>
          <span className="font-semibold">Location: </span>{appointmentDetails.location}
        </p>
      </div>

      {/* Modal for Eligibility Check */}
      <Modal
        isOpen={isEligibilityModalOpen}
        onRequestClose={closeEligibilityModal}
        contentLabel="Check Eligibility"
        className="modal bg-white p-6 rounded-lg shadow-lg"
        overlayClassName="overlay bg-gray-900 bg-opacity-75 fixed inset-0 flex justify-center items-center z-20"
      >
        <h2 className="text-xl font-bold mb-4">Eligibility Check</h2>
        <div className="space-y-4">
          {questions.map((question, index) => (
            <div key={index} className="flex items-center space-x-4">
              <p className="flex-1">{question}</p>
              <button
                onClick={() => handleResponseChange(index, "yes")}
                className={`px-4 py-2 rounded-lg ${responses[index] === "yes"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200"
                  }`}
              >
                Yes
              </button>
              <button
                onClick={() => handleResponseChange(index, "no")}
                className={`px-4 py-2 rounded-lg ${responses[index] === "no"
                    ? "bg-red-600 text-white"
                    : "bg-gray-200"
                  }`}
              >
                No
              </button>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={checkEligibility}
            className="bg-green-600 text-white px-4 py-2 rounded-lg"
          >
            Check Eligibility
          </button>
          <button
            onClick={closeEligibilityModal}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg"
          >
            Close
          </button>
        </div>
      </Modal>

      {/* Modal for Booking Appointment */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Book Appointment"
        className="modal bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full"
        overlayClassName="overlay bg-gray-900 bg-opacity-75 fixed inset-0 flex justify-center items-center z-20"
      >
        <h2 className="text-xl font-bold mb-4">Book Appointment</h2>
        <div className="flex flex-wrap">
          {/* Left Section: Dropdown and Center Details */}
          <div className="w-full md:w-2/3 pr-4">
            {/* Dropdown to select the center */}
            <div className="mb-4">
              <label className="block font-semibold mb-2">Center Name:</label>
              <select
                value={centerName}
                onChange={(e) => setCenterName(e.target.value)}
                className="p-2 border rounded-lg w-full"
              >
                <option value="" disabled>
                  Select a Center
                </option>
                {datastore.blood_donation_centers.map((center) => (
                  <option key={center.name} value={center.name}>
                    {center.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Center Details */}
            <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
              {centerName ? (
                datastore.blood_donation_centers
                  .filter((center) => center.name === centerName)
                  .map((selectedCenter) => (
                    <div key={selectedCenter.name} className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg font-semibold">{selectedCenter.name}</h3>
                        <p className="text-sm text-gray-600">{selectedCenter.address}</p>
                      </div>
                      <p className="text-blue-500 font-medium">
                        {selectedCenter.phone || "N/A"}
                      </p>
                    </div>
                  ))
              ) : (
                <p className="text-gray-500">Select a center to see details.</p>
              )}
            </div>

            {/* Time Slots */}
            <div className="mb-4">
              <label className="block font-semibold mb-2">Select Time:</label>
              <div className="grid grid-cols-3 gap-2">
                {timeSlots.map((slot) => (
                  <button
                    key={slot}
                    className={`p-2 rounded-lg flex items-center justify-center text-center ${selectedTime === slot ? "bg-blue-600 text-white" : "bg-gray-200"
                      }`}
                    onClick={() => setSelectedTime(slot)}
                  >
                    {slot}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Right Section: Calendar */}
          <div className="w-full md:w-1/3 pl-4">
            <div className="mb-4">
              <label className="block font-semibold mb-2">Select Date:</label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                className="p-2 border rounded-lg w-full"
                inline
              />
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={closeModal}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleBookAppointment}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg"
            disabled={!selectedTime || !centerName}
          >
            Book Appointment
          </button>
        </div>
      </Modal>
    </motion.div>
  );
};

export default AppointmentsSection;