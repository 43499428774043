import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // Refresh token
          const token = await user.getIdToken(true);
          localStorage.setItem('token', token);

          // Fetch user data from backend (to retain role info which doesnt persist when onAuthStateChanged is triggered for additional info other than login)
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const { user: userData } = await response.json();
            setUser({ ...user, ...userData });
          } else {
            setUser(null);
            localStorage.removeItem('token');
          }
        } else {
          setUser(null);
          localStorage.removeItem('token');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe;
  }, []);

  // token refresh on interval
  useEffect(() => {
    const refreshToken = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken(true);
        localStorage.setItem('token', token);
      }
    }, 55 * 60 * 1000); // Refresh every 55 minutes

    return () => clearInterval(refreshToken);
  }, []);

  const signup = async (email, name, phoneNumber, donorCardNo, password, role, institutionCode = null, adminSecretKey = null) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      // Send additional user data to backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
          // 'X-CSRF-Token': csrfToken, // TODO: add csrf token
        },
        body: JSON.stringify({
          role,
          name,
          phoneNumber,
          donorCardNo,
          institutionCode: role === 'institution' ? institutionCode : null,
          adminSecretKey: role === 'admin' ? adminSecretKey : null
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create user in backend');
      }

      return { user, role };
    } catch (error) {
      if (auth.currentUser) {
        await auth.currentUser.delete();
      }
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      // fetch user role from backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data from backend');
      }

      const { user: userData } = await response.json();

      setUser({ ...user, ...userData });

      return {
        role: userData.role
      };
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('token');
    } catch (error) {
      console.error('Failed to log out', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, signup, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};