import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const DonorRewardSign = () => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const animateFrameRef = useRef(null);

  useEffect(() => {
    // Set up the scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff); // White background

    const camera = new THREE.PerspectiveCamera(50, 2, 0.1, 1000);
    camera.position.set(0, 0, 20); // Move closer to focus only on the cube

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    rendererRef.current = renderer;
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Create a star shape
    const starShape = new THREE.Shape();
    const outerRadius = 5;
    const innerRadius = 2.5;
    const spikes = 5;

    for (let i = 0; i < spikes * 2; i++) {
      const radius = i % 2 === 0 ? outerRadius : innerRadius;
      const angle = (i / (spikes * 2)) * Math.PI * 2;
      starShape.lineTo(Math.cos(angle) * radius, Math.sin(angle) * radius);
    }
    starShape.closePath();

    // Extrude the 2D star shape to make it 3D
    const extrudeSettings = {
      depth: 1,
      bevelEnabled: true,
      bevelThickness: 0.2,
      bevelSize: 0.2,
      bevelSegments: 1,
    };
    const starGeometry = new THREE.ExtrudeGeometry(starShape, extrudeSettings);
    const starMaterial = new THREE.MeshStandardMaterial({
      color: 0xab0a0f, 
      emissive: 0xab0a0f
    });

    const starMesh = new THREE.Mesh(starGeometry, starMaterial);
    scene.add(starMesh);

    // Add lighting to highlight the star
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1.5, 100);
    pointLight.position.set(10, 15, 20);
    scene.add(pointLight);

    // Animation loop to add rotation to the star
    const animate = () => {
      animateFrameRef.current = requestAnimationFrame(animate);
      starMesh.rotation.y += 0.01; // Rotate the star slowly
      rendererRef.current.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      if (!mountRef.current || !renderer) return;
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (animateFrameRef.current) {
        cancelAnimationFrame(animateFrameRef.current);
      }
      if(mountRef.current && rendererRef.current  ) {
        try {
          mountRef.current.removeChild(rendererRef.current.domElement);
        } catch (error) {
          console.error('Error removing renderer dom element:', error);
        }
      }

      // dispose of three.js resources
      renderer.dispose();
      scene.clear();
    };
  }, []);

  return <div ref={mountRef} style={{ width: '300px', height: '150px' }} />;
};

export default DonorRewardSign;
