import React, { useEffect, useState } from 'react';

const NewNftMintingList = () => {
  const [notifications, setNotifications] = useState([]);
  const [mintingId, setMintingId] = useState(null); // track which notification is being minted

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setNotifications(data.notifications || []);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleMint = async (notificationId, walletAddress) => {
    try {
      setMintingId(notificationId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          notificationId,
          walletAddress
        })
      });
      
      const data = await response.json();
      if (data.success) {
        await fetchNotifications(); // Refresh the list after minting
      }
    } catch (error) {
      console.error('Failed to mint NFT:', error);
    } finally {
      setMintingId(null);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">NFT Minting List</h2>
      <div className="overflow-y-auto max-h-[400px]">
        {notifications.length === 0 ? (
          <p className="text-gray-500 text-center py-4">No pending notifications</p>
        ) : (
          notifications.map((notification) => (
            <div 
              key={notification.id} 
              className="p-4 mb-2 border rounded-lg flex justify-between items-center bg-gray-50 hover:bg-gray-100"
            >
              <div className="flex-1">
                <p className="font-medium text-gray-700">
                  Email: <span className="text-blue-600">{notification.email}</span>
                </p>
                <p className="text-sm text-gray-600">
                  Wallet: <span className="font-mono">{notification.walletAddress}</span>
                </p>
                <p className="text-xs text-gray-500">
                  Status: <span className="font-semibold">{notification.status}</span>
                </p>
              </div>
              <button
                onClick={() => handleMint(notification.id, notification.walletAddress)}
                disabled={mintingId === notification.id || notification.status !== 'pending'}
                className={`ml-4 px-4 py-2 rounded-md ${
                  mintingId === notification.id || notification.status !== 'pending'
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                }`}
              >
                {mintingId === notification.id ? 'Minting...' : 'Mint NFT'}
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NewNftMintingList;