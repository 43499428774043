import React from 'react';
import logo from '../../assets/Logo1.jpg';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-l from-blue-500 to-red-500 text-white py-8">
      <div className="container mx-auto px-4 lg:px-16">
        <div className="border-t border-white pt-8">

          {/* Help Centre and Provide Feedback */}
          <div className="flex flex-col lg:flex-row justify-between items-center mb-6 space-y-4 lg:space-y-0">
            {/* Links Section */}
            <div className="w-full lg:w-auto flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 lg:space-x-8">
              <a href="#" className="text-lg text-white hover:underline">Help Centre</a>
              <a href="#" className="text-lg text-white hover:underline">Provide feedback</a>
            </div>

            {/* Logo and Description */}
            <div className="flex items-center justify-center lg:justify-start">
              <a href="#" className="text-white hover:underline">
                <img
                  src={logo}
                  alt="CDIC Logo"
                  className="w-20 h-auto"
                />
              </a>
              <p className="text-sm ml-2">TrustBlu is a lorem ipsum</p>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="border-t border-white pt-4 text-center">
            <p className="text-sm">You are securely signed on. <a href="#" className="text-blue-500 hover:underline">Learn more</a></p>
            <p className="text-sm">© 2024 TrustBlu. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;