import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import AdminDashboard from './Components/AdminDashboard/Dashboard';
import UserDashboard from './Components/Dashboard/Dashboard'; // Import your Dashboard component
import InstitutionDashboard from './Components/InstitutionDB/InstitutionDashboard';
import LandingPage from './Components/Landing/LandingPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';

function AppRoutes() {
  const { user, loading } = useAuth();
  const location = useLocation();

  // only for protected routes
  const requireAuth = (element, allowedRoles) => {
    // Add loading check
    if (loading) {
      return <div>Loading...</div>;
    }

    if (!user) {
      // save attempted location and redirect to landing page
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    // check if user has the required role
    if (!allowedRoles.includes(user.role)) {
      // redirect to their appropriate dashboard based on their role
      switch (user.role) {
        case 'user':
          return <Navigate to="/user-dashboard" replace />;
        case 'admin':
          return <Navigate to="/admin-dashboard" replace />;
        case 'institution':
          return <Navigate to="/institution-dashboard" replace />;
        default:
          return <Navigate to="/" replace />;
      }
    }

    return element;
  };
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" />

      <Route 
        path="/user-dashboard" 
        element={requireAuth(<UserDashboard />, ['user'])} 
      />
      <Route 
        path="/admin-dashboard" 
        element={requireAuth(<AdminDashboard />, ['admin'])} 
      />
      <Route 
        path="/institution-dashboard" 
        element={requireAuth(<InstitutionDashboard />, ['institution'])} 
      />
      {/* catch-all route for unknown paths or redirects */}
      <Route 
        path="*" 
        element={<Navigate to="/" replace />} 
      />
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
