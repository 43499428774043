import React, { useState } from 'react';

const TaskSection = () => {
  const [selectedProblem, setSelectedProblem] = useState(null); // State for selected problem
  const [showModal, setShowModal] = useState(false); // State for showing modal
  const [filterType, setFilterType] = useState('all'); // State for filtering tasks (default to 'all')

  // Sample problems array with status
  const problems = Array.from({ length: 12 }, (_, index) => ({
    title: `Problem ${index + 1}`,
    price: `$${10 + index}-${20 + index} USD`,
    description: `Detailed information about Problem ${index + 1}.`,
    status: index % 2 === 0 ? 'active' : 'completed', // Alternate status for demo
  }));

  // Function to handle problem click and show modal
  const handleProblemClick = (problem) => {
    setSelectedProblem(problem); // Set the selected problem
    setShowModal(true); // Show modal
  };

  // Function to close modal
  const closeModal = () => {
    setShowModal(false); // Hide modal
  };

  // Filter the problems based on the filterType state
  const filteredProblems = problems.filter((problem) => {
    if (filterType === 'all') return true;
    return problem.status === filterType;
  });

  return (
    <div className="relative p-6 bg-customGray rounded-lg cursor-pointer">
      {/* Filter options with responsive alignment */}
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
        {/* All Tasks filter (badge style with hover effect) */}
        <div
          onClick={() => setFilterType('all')}
          className={`cursor-pointer px-4 py-2 rounded-full  ${filterType === 'all' ? 'bg-blue-500 text-white' : 'text-black'
            } hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out`}
        >
          All Tasks
        </div>

        {/* Active Tasks filter (badge style with hover effect) */}
        <div
          onClick={() => setFilterType('active')}
          className={`cursor-pointer px-4 py-2 rounded-full ${filterType === 'active' ? 'bg-red-500 text-white' : 'text-black'
            } hover:bg-red-500 hover:text-white transition duration-300 ease-in-out`}
        >
          Active Tasks
        </div>

        {/* Completed Tasks filter (badge style with hover effect) */}
        <div
          onClick={() => setFilterType('completed')}
          className={`cursor-pointer px-4 py-2 rounded-full ${filterType === 'completed' ? 'bg-green-500 text-white' : 'text-black'
            } hover:bg-green-500 hover:text-white transition duration-300 ease-in-out`}
        >
          Completed Tasks
        </div>
      </div>

      {/* List of problems (filtered) with fixed height */}
      <div className="overflow-y-auto max-h-[670px] h-[485px]">
        {filteredProblems.length > 0 ? (
          filteredProblems.map((problem, index) => (
            <div
              key={index}
              className="relative p-4 mb-2 rounded-lg flex justify-between items-center bg-white cursor-pointer overflow-hidden"
              onClick={() => handleProblemClick(problem)} // Handle click to show modal
            >
              {/* Gloss effect using pseudo-element-like structure */}
              <div className="absolute inset-0 bg-blue-500 opacity-100 rounded-lg pointer-events-none transform rotate-[-45deg] translate-x-[50%] translate-y-[20%]"></div>

              <div className="relative flex justify-between w-full">
                <span className="text-customBlue font-bold">{problem.title}</span>
                <span className="text-customRed font-bold mr-10">{problem.price}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 mt-6">No tasks available</div>
        )}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">{selectedProblem?.title}</h3>
            <p className="mb-4">{selectedProblem?.description}</p>
            <p className="font-bold">{selectedProblem?.price}</p>
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskSection;